.Modal {
  display: flex;
  background-color: rgba(10, 10, 10, 0.99);
  position: fixed;
  z-index: 100;
  border-radius: 10px;
  padding: 1.5em 1.5em;
  top: 15%;
  left: 20%;
  right: 20%;
  bottom: 10%;
  overflow-y: scroll;
  border: 1px solid grey;
}

.Modal-close {
  position: absolute;
  top: 1%;
  right: 1%;
  text-align: center;
  width: 10%;
}

.Modal-close button {
  border-radius: 50%;
  color: white;
  background-color: rgba(20, 20, 20);
  border: 2px solid rgba(255, 255, 255);
  cursor: pointer;
}

.Modal-close button:hover {
  cursor: pointer;
  background-color: rgb(56, 56, 56);
  border: 1px solid rgb(0, 0, 0);
}

.Modal-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Modal-Button {
  width: fit-content;
  padding: 0.5rem;
  margin: 0.5em;
  border-radius: 54px;
  color: white;
  background-color: black;
  border: 1px solid rgba(56, 56, 56);
  transition: background-color 0.5s ease;
}

.Modal-Button:hover {
  cursor: pointer;
  background-color: rgb(56, 56, 56);
  border: 1px solid rgb(0, 0, 0);
}

.Modal-image,
.Modal-contents {
  max-height: 100%;
  max-width: 90%;
}

.Modal-contents {
  width: 100%;
}

.Modal-contents ul {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.Modal ul li {
  list-style: none;
  margin: 0.2em 0;
}

.Modal-streaming-regions {
  display: flex;
  justify-content: space-between;
}

.Modal-platforms,
.Modal-regions {
  margin: 0 0.5em;
  text-align: center;
  width: 50%;
}
/* SYNOPSIS TRANSITION */
.ModalSynopsis-enter {
  transform: translateX(100%);
}
.ModalSynopsis-enter-active {
  transform: translateX(0%);
  transition: transform 300ms;
}
.ModalSynopsis-exit {
  transform: translateX(0%);
}
.ModalSynopsis-exit-active {
  transform: translateX(100%);
  transition: transform 300ms;
}

/* INFO TRANSITION */

.ModalInfo-enter {
  transform: translateX(200%);
}
.ModalInfo-enter-active {
  transform: translateX(0%);
  transition: transform 300ms;
}
.ModalInfo-exit {
  transform: translateX(0%);
}
.ModalInfo-exit-active {
  transform: translateX(200%);
  transition: transform 300ms;
}
/*------- MODAL Scrollbar ------- */

/* width */

.Modal::-webkit-scrollbar {
  height: 1em;
  width: 0.5em;
}

/* Track */

.Modal::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */

.Modal::-webkit-scrollbar-thumb {
  background: rgba(74, 74, 74, 1);
  border-radius: 10px;
  width: 12px;
}
.Modal::-webkit-scrollbar-thumb:hover {
  background: rgba(74, 74, 74, 1);
  border-radius: 10px;
  width: 12px;
}
