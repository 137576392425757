.Nav-list li {
    list-style: none;
    margin: 0 .5em;
}

.Nav-list {
    display: flex;
    width: 100%; 
    justify-content: space-around; 
    position: sticky;
    }


.Nav-list a,
.Nav-List a:active,
.Nav-List a:visited {
    text-decoration: none;
    color: white;
}
