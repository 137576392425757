.Miscellaneous {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
}

.Miscellaneous-header h2 {
  text-align: left;
}

.Miscellaneous-content {
  display: flex;
}

.Miscellaneous-list {
  width: 90%;
}

.Miscellaneous-lists-transition-enter {
  opacity: 0;
}
.Miscellaneous-lists-transition-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.Miscellaneous-lists-transition-exit {
  opacity: 1;
}
.Miscellaneous-lists-transition-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}
