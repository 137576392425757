.MovieList {
  display: flex;
  align-items: center;
  min-height: fit-content;
  width: 100%;
}

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

/* width */

.MovieList::-webkit-scrollbar {
  height: 1em;
}

/* Track */

.MovieList::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */

.MovieList::-webkit-scrollbar-thumb {
  background: rgba(74, 74, 74, 0.5);
  border-radius: 10px;
  width: 12px;
}
.MovieList::-webkit-scrollbar-thumb:hover {
  background: rgba(74, 74, 74, 1);
  border-radius: 10px;
  width: 12px;
}
