.StreamingPlatform {
  overflow-wrap: anywhere;
  min-width: 100%;
}
.StreamingPlatform ul {
  display: flex;
  flex-direction: row;
}

.StreamingPlatform ul li {
  margin: 0 0.2em;
}

.disclaimer {
  font-size: 8px;
}

.disclaimer a {
  text-decoration: none;
  color: white;
}

.StreamingPlatform-btn {
  min-width: max-content;
  max-width: 20%;
}
