
.handle {
    display: flex;
    font-size: large;
    justify-content: center;
    background-color: rgb(30, 25, 25);
    width: 5%;
    cursor: pointer;
    opacity: .2;
    color: white;
    align-items: center;
    border-radius: 10px;
    z-index: 10;

}

.handle:hover {
    opacity: .9;
    transition: opacity .2s;
}