.Discover {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
}

.Discover-header h2 {
  text-align: left;
}

.Discover-content {
  display: flex;
}

.Discover-list {
  width: 90%;
}

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
