.TvList {
    display: flex;
    align-items: center;
    min-height: fit-content;
    width: 100%;
}

.TvList ul {
    display: flex;
    scrollbar-color: rgba(255, 255, 255, 0.5);
}