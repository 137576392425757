.GenreSelectionButton {
  margin: 0.1em;
  padding: 0.5em 0.75em;
  border-radius: 14px;
  text-align: center;
}

.GenreSelectionButton-active-false {
  border: 1px solid rgb(56, 56, 56);
  background-color: black;
  color: white;
  cursor: pointer;
}

.GenreSelectionButton-active-false:hover {
  background-color: rgb(56, 56, 56);
  border: rgb(0, 0, 0);
}

.GenreSelectionButton-active-true {
  background-color: rgb(56, 56, 56);
  border: rgb(0, 0, 0);
  color: white;
  cursor: pointer;
}

.GenreSelectionButton-active-true:hover {
  background-color: black;
  border: rgb(56, 56, 56);
  cursor: pointer;
}
