.AdvancedSearch {
  display: flex;
}

.AdvancedSearch-Inputs ul li {
  list-style: none;
  margin-bottom: 1em;
}

.AdvancedSearch label {
  margin: 0em 1em;
}

#AdvancedSearch-media-type {
  display: flex;
  flex-direction: column;
}

#AdvancedSearch-Media {
  display: flex;
}

.AdvancedSearch-Inputs,
.AdvancedSearch-Results {
  display: flex;
}

.AdvancedSearch-Results {
  width: 60%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.AdvancedSearch-Inputs {
  max-width: 20%;
  text-align: left;
}

.AdvancedSearch-button {
  border-radius: 54px;
  background-color: black;
  color: white;
  margin: 0.2rem;
  transition: background-color 0.5s;
  transition: border-color 0.5s;
}

.AdvancedSearch-button:hover {
  cursor: pointer;
  background-color: rgb(59, 59, 59);
  border-color: rgb(59, 59, 59);
}
