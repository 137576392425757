.SearchBar {
  width: 100%;
  display: flex;
  justify-content: center;
  /*  align-items: center; */
}

.SearchBar input,
.SearchBar button {
  margin: 0.5em 0;
  width: 50%;
  border-radius: 54px;
  text-align: center;
  color: white;
  background-color: rgba(20, 20, 20);
  border: 2px solid rgba(255, 255, 255);
  transition: width 0.5s;
}

.SearchBar input:hover {
  width: 100%;
  transition: width 0.5s;
}
