.Movie {
  flex: 0 1 20%;
  margin: 0 0.5em;
}

.Movie img {
  border-radius: 10px;
  cursor: pointer;
  opacity: 0.8;
}

.Movie img:hover {
  opacity: 1;
  box-shadow: 10px 0px 30px rgba(72, 72, 72, 0.5),
    -10px -0px 30px rgba(72, 72, 72, 0.5);
  transition: all 0.5s;
}

.Movie-modal-transition-enter {
  opacity: 0;
}
.Movie-modal-transition-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.Movie-modal-transition-exit {
  opacity: 1;
}
.Movie-modal-transition-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}
