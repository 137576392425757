.Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px double grey;
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: black;
}

.Header-text {
  display: flex;
  width: 40%;
  align-items: center;
  justify-content: center;
}

.Header-Nav {
  display: flex;
  width: 60%;
  align-items: center;
}

.Header-text a,
.Header-text a:active,
.Header-text a:visited {
  text-decoration: none;
  color: white;
}
